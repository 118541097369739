import {Component, Input, OnInit} from '@angular/core';
import { Application } from '../api/generated/models/Application';
import { ApplicationsApiService, ApplicationSecurityScanApiService, ScansApiService } from '../api/generated';
import { ActivatedRoute, Router } from '@angular/router';
import { SecurityScan } from '../api/generated/models/SecurityScan';
import {SecurityScanCustom} from "./SecurityScanCustom";
@Component({
  selector: 'app-project-history-view',
  templateUrl: './project-history-view.component.html',
  styleUrls: ['./project-history-view.component.css']
})


export class ProjectHistoryViewComponent implements OnInit{
  @Input() inData: Application | null = null;
  application: Application | null  = null;
  projectId: string | null = null;
  securityScans: Array<SecurityScanCustom> | null = null;
  constructor(
    private applicationSecurityScanApiService: ApplicationSecurityScanApiService,
    private router: Router,
    private route: ActivatedRoute,
    private applicationApiService: ApplicationsApiService,
    private scansApiService: ScansApiService
  ) {}

  private loadSecurityScan(): void {
    if (this.application != null) {
      if (this.application.applicationId) {
        this.applicationSecurityScanApiService.getApiApplicationsSecurityScanAll(this.application.applicationId).subscribe(
          securityScan => {
            this.securityScans = securityScan;
            for(let i = 0; i < this.securityScans.length; i++){
              if(this.securityScans[i].securityScanId != null){
                // @ts-ignore
                this.scansApiService.getApiScansGetMainScore(this.securityScans[i].securityScanId).subscribe(
                  securityScanPrimaryGrade => {
                    // @ts-ignore
                    this.securityScans[i].securityScanPrimaryGrade = securityScanPrimaryGrade;
                    console.log('grades loaded:', securityScanPrimaryGrade);
                  });
              }
            }
          },
          error => {
            console.error(error);
            throw new Error('Error retrieving security scan.');
          }
        );
      }
    }
  }


  endPointGradeColor(grade: string| undefined| null): string{
    var color = "";
    if(grade == null){
      return color = "rating_x";
    }
    if (grade == "A+" || grade == "A"){
      color = "rating_g";
    } else if (grade == "B" || grade == "C" || grade == "D" || grade == "E"){
      color = "rating_a";
    } else {
      color = "rating_r";
    }
    return color;
  }

  handleClick(securityScan: SecurityScan| undefined | null): void {
    console.log("clicked+ " + securityScan?.securityScanId);
    this.router.navigate(['project/'+this.projectId +'/history/'+  securityScan?.securityScanId]);

  }

  AverageGrade(grade1: string | undefined | null, grade2: string | undefined | null): string {
    // Define a map for the grade system
    const gradeSystem = {
      'A+': 7,
      'A': 6,
      'B': 5,
      'C': 4,
      'D': 3,
      'E': 2,
      'F': 1
    };

    // Define the inverse map to get back grades from scores
    const gradeSystemInverse = {
      7: 'A+',
      6: 'A',
      5: 'B',
      4: 'C',
      3: 'D',
      2: 'E',
      1: 'F'
    };

    // Default score in case of undefined or null
    const defaultScore = 4; // Default to 'C'

    // Convert the grades to scores
    // @ts-ignore
    const score1 = grade1 !== null && grade1 !== undefined ? gradeSystem[grade1] : defaultScore;
    // @ts-ignore
    const score2 = grade2 !== null && grade2 !== undefined ? gradeSystem[grade2] : defaultScore;

    // Compute the average score
    const avgScore = Math.round((score1 + score2) / 2);

    // Map the average score back to a grade
    // @ts-ignore
    const avgGrade = gradeSystemInverse[avgScore];

    return avgGrade;
  }

  ngOnInit(): void {
    if (this.inData == null) {
      throw new Error('Input data is null.');
    }

    {
      // @ts-ignore
      this.projectId = this.inData.applicationId.toString();
      console.log(this.projectId);

          if (this.projectId != null) {
                this.application = this.inData;
                console.log('Application loaded:', this.application);
                this.loadSecurityScan();
              }

      }
    }
  }
