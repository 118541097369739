/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { ActivatedScanDTO } from '../models/ActivatedScanDTO';
import type { ApplicationSetting } from '../models/ApplicationSetting';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable({
  providedIn: 'root',
})
export class ApplicationSettingsApiService {

    constructor(public readonly http: HttpClient) {}

    /**
     * @returns ApplicationSetting Success
     * @throws ApiError
     */
    public getApiApplicationSettings(): Observable<Array<ApplicationSetting>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/ApplicationSettings',
        });
    }

    /**
     * @param requestBody 
     * @returns ApplicationSetting Success
     * @throws ApiError
     */
    public postApiApplicationSettings(
requestBody?: ApplicationSetting,
): Observable<ApplicationSetting> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/ApplicationSettings',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id 
     * @returns ApplicationSetting Success
     * @throws ApiError
     */
    public getApiApplicationSettings1(
id: number,
): Observable<ApplicationSetting> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/ApplicationSettings/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id 
     * @param requestBody 
     * @returns any Success
     * @throws ApiError
     */
    public putApiApplicationSettings(
id: number,
requestBody?: ApplicationSetting,
): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/api/ApplicationSettings/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id 
     * @returns any Success
     * @throws ApiError
     */
    public deleteApiApplicationSettings(
id: number,
): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/api/ApplicationSettings/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param applicationSettingsId 
     * @param requestBody 
     * @returns any Success
     * @throws ApiError
     */
    public postApiApplicationSettingsPostActivatedScan(
applicationSettingsId: number,
requestBody?: Array<ActivatedScanDTO>,
): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/ApplicationSettings/postActivatedScan/{applicationSettingsID}',
            path: {
                'applicationSettingsID': applicationSettingsId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
