import { Component } from '@angular/core';
import {Application} from "../api/generated/models/Application";
import {SecurityScan} from "../api/generated/models/SecurityScan";
import {
  ApplicationsApiService,
  ApplicationSecurityScanApiService,
  SecurityScanApiService
} from "../api/generated";
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-project-scan-history-view',
  templateUrl: './project-scan-history-view.component.html',
  styleUrls: ['./project-scan-history-view.component.css']
})
export class ProjectScanHistoryViewComponent {
  projectId: string | null = null;
  historySecurityScanID: string | null = null;
  application: Application | null  = null;
  securityScan: SecurityScan | null = null;
  selectedIndex: number = 0;
  hide = false;

  constructor(
    private applicationSecurityScanApiService: ApplicationSecurityScanApiService,
    private router: Router,
    private route: ActivatedRoute,
    private applicationApiService: ApplicationsApiService,
    private securityScanApiService: SecurityScanApiService,
  ) {
    this.projectId = this.route.snapshot.paramMap.get('projectId');
    this.historySecurityScanID = this.route.snapshot.paramMap.get('historySecurityScanID');
    console.log(this.projectId);

    if(this.router.getCurrentNavigation() != null) {
      // @ts-ignore
      const projectX = this.router.getCurrentNavigation().extras.state;
      if (projectX == null) {
        if (this.projectId != null) {
          const numberProjectID: number = +this.projectId;
          this.applicationApiService.getApiApplications1(numberProjectID).subscribe(
            application => {
              this.application = application;
              console.log('Application loaded:', this.application);
              this.loadSecurityScan(this.historySecurityScanID);
            },
            error => {
              console.error(error);
              throw new Error('Error retrieving application.');
            }
          );
        } else {
          throw new Error('Project ID is null.');
        }
      } else {
        this.application = projectX;
        this.loadSecurityScan(this.historySecurityScanID);
      }
    }else{
      throw new Error('router.getCurrentNavigation is null.');
    }
  }

  protected getSecurityScanID(): number{
    if (this.securityScan?.securityScanId == null){
      throw Error();
    }
    return this.securityScan.securityScanId;
  }
  private loadSecurityScan(securityscanId:string|null): void {
    if (this.application != null && this.application.applicationId && securityscanId != null) {
      console.log('Loading security scan:', securityscanId);
      this.securityScanApiService.getApiSecurityScan1(Number(securityscanId)).pipe(
        catchError(error => {
          console.error('Error in loadSecurityScan:', error);
          // Handle the error and return a safe value to the observable chain
          // Here we are returning null. Modify as needed
          return throwError('Error occurred while loading security scan.');
        })
      ).subscribe(
        securityScan => {
          this.securityScan = securityScan;
          console.log('Security scan loaded:', this.securityScan);
          console.log('Application loaded:', this.application);
        },
        error => {
          // Handle the error here if it wasn't caught by catchError
          console.error('Subscription error in loadSecurityScan:', error);
        }
      );
    } else {
      console.error('Application or application ID or securityscanId is null.');
    }
  }
}
