import {Component, Inject, Input, OnInit} from '@angular/core';
import {Application} from "../api/generated/models/Application";
import {ApplicationSetting} from "../api/generated/models/ApplicationSetting";
import {ApplicationsApiService, ApplicationSettingsApiService, ScanApplicationApiService} from "../api/generated";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ScanApplicationDTO} from "../api/generated/models/ScanApplicationDTO";
import {ActivatedScanDTO} from "../api/generated/models/ActivatedScanDTO";
import {Router} from "@angular/router";
import {MAT_SNACK_BAR_DATA, MatSnackBar, MatSnackBarModule, MatSnackBarRef} from "@angular/material/snack-bar";
import {MatButtonModule} from "@angular/material/button";
import {SnackBarAddProjectComponent} from "../add-project-view/add-project-view.component";

@Component({
  selector: 'app-project-settings-view',
  templateUrl: './project-settings-view.component.html',
  styleUrls: ['./project-settings-view.component.css']
})
export class ProjectSettingsViewComponent implements OnInit {
  @Input() inData: Application | null = null;
  settingsIndex = 0;
  applicationgSettings: ApplicationSetting | null = null;
  activatedScans: Array<ActivatedScanDTO> | null = null;
  scanApplications: Array<ScanApplicationDTO> | null = null;
  application: Application | null = null;
  domainForm!: FormGroup;
  scheduleForm!: FormGroup;
  azureForm!: FormGroup;
  activatedForm!: FormGroup;
  inputApplicationName: string = '';
  isNameMatching: boolean = false;

  constructor( private  _snackBar: MatSnackBar, private router: Router, private applicationgSettingsAPiService: ApplicationSettingsApiService, private scanApplicationApiService:ScanApplicationApiService, private applicationApiService: ApplicationsApiService, private formBuilder: FormBuilder) {
  }

  changeDomainName() {
    if (this.domainForm.valid) {
      const domainName = this.domainForm.controls['domainName'].value;

      // Check if inData and applicationId are defined
      if (this.inData && this.inData.applicationId !== undefined) {
        this.applicationApiService.putApiApplicationsUpdateApplicationDomainName(this.inData.applicationId, domainName).subscribe(
          x => {
            window.location.reload();
            // CHANGE THIS <------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
          }
        );
      } else {
        console.error('inData or applicationId is not defined');
      }
    }
  }

  validateInput() {
    if(this.application != null){
      this.isNameMatching = this.inputApplicationName === this.application.applicationName;
    }
  }
  confirmDeletion() {
    if (confirm('Are you sure you want to delete this application? This action cannot be undone.')) {
      console.log('Deleting application:', this.application?.applicationName);
      this.applicationApiService.deleteApiApplications(this.application!.applicationId!).subscribe(
        x => {
          this.openSnackBar(this.application);
          this.router.navigate(['/projects']);
        });
    }
  }

  openSnackBar(message: any) {
    this._snackBar.openFromComponent(SnackBarRemovedProjectComponent, {
      data: message,  // pass the object directly
      duration: 10 * 1000,
    });
  }
  updateActivatedScan() {
    this.scanApplicationApiService.putApiScanApplicationUpdateActivated(this.applicationgSettings!.applicationSettingsId!, this.activatedScans!).subscribe(
      x => {
        window.location.reload();
      });
  }
  changeScheduleForScan() {
    if (this.scheduleForm.valid) {
      const selectedSchedule = this.scheduleForm.controls['scheduleForScan'].value;
      let date = this.calculateScheduleforScan(selectedSchedule).toISOString();
      var settings = this.applicationgSettings;

      settings!.scheduleForScan = date;
      settings!.frequencyScan = selectedSchedule;

      this.applicationgSettingsAPiService.putApiApplicationSettings(settings!.applicationSettingsId!, settings!).subscribe(
        x => {
          window.location.reload();

        });
    }
  }
  changeAzureDevOpsLink() {
    if(this.azureForm.valid) {
      var settings = this.applicationgSettings;
      settings!.azureDevopsPageLink = this.azureForm.controls['azureLink'].value;
      this.applicationgSettingsAPiService.putApiApplicationSettings(settings!.applicationSettingsId!, settings!).subscribe(
        x => {
          window.location.reload();

        });
    }
  }

  getScanApplicationName(id: number | undefined): string {
    if (this.scanApplications) {
      return this.scanApplications.find(x => x.scanApplicationId === id)?.scanName ?? '';
    } else {
      return '';
    }
  }

  changeSettingsIndex(index: number): void {
    this.settingsIndex = index;
  }

  calculateScheduleforScan(value: String): Date {
    let date = new Date();
    let frequency = '';
    switch(value) {
      case 'every day':
        date.setDate(date.getDate() + 1);
        frequency = 'every day';
        break;
      case 'every week':
        date.setDate(date.getDate() + 7);
        frequency = 'every week';
        break;
      case 'every two weeks':
        date.setDate(date.getDate() + 14);
        frequency = 'every two weeks';
        break;
      case 'every month':
        date.setMonth(date.getMonth() + 1);
        frequency = 'every month';
        break;
    }
    return date;
  }

  ngOnInit(): void {
    if (this.inData?.applicationId == null) {
      throw new Error('Input data is null.');
    } else {

      this.applicationgSettingsAPiService.getApiApplicationSettings1(this.inData.applicationId).subscribe(
        applicationSettings => {
          this.applicationgSettings = applicationSettings;
          this.scanApplicationApiService.getApiScanApplicationActivated(applicationSettings.applicationSettingsId!).subscribe(
            activatedScans => {
              this.activatedScans = activatedScans;
              console.log(activatedScans);
            }
          )
          console.log(applicationSettings);
        });

      this.applicationApiService.getApiApplications1(this.inData.applicationId).subscribe(
        application => {
          this.application = application;
        });
      this.scanApplicationApiService.getApiScanApplication().subscribe(
        scanApplication => {
          this.scanApplications = scanApplication;
        });

      this.activatedForm = this.formBuilder.group({
        activated: this.formBuilder.array([
          this.formBuilder.group({
            applicationSettingsId: '',
            scanApplicationId: '',
            activated: ''
          })
        ])
      });

      this.domainForm = this.formBuilder.group({
        domainName: [
          '', // initial value
          [ // synchronous validators
            Validators.required,
            Validators.pattern(/^(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]$/)
          ]
        ]
      });

      this.scheduleForm = this.formBuilder.group({
        scheduleForScan: [
          '', // initial value
          [ // synchronous validators
            Validators.required
          ]
        ]
      });
      this.azureForm = this.formBuilder.group({
        azureLink: [
          '', // initial value
          [ // synchronous validators
            Validators.required,
            Validators.pattern(/^https:\/\/([\w-]+\.)+[\w-]+(\/[\w- ;,./?%&=]*)?$/)
            ]
          ]
      });

    }

  }
}
@Component({
  selector: 'removed-project-view-snack-bar',
  templateUrl: 'snack-bar-notification-project-removed.html',
  styles: [
    `
    :host {
      display: flex;
    }

    .snack-bar-remove-project {
      color: #009ddf;
    }
  `,
  ],
  standalone: true,
  imports: [MatButtonModule, MatSnackBarModule],
})
export class SnackBarRemovedProjectComponent {
  data: any;

  constructor(@Inject(MAT_SNACK_BAR_DATA) public snackBarData: any, public snackBarRef: MatSnackBarRef<SnackBarRemovedProjectComponent>){
    // Directly assign the data object
    this.data = snackBarData;
  }
}
