import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {ProjectViewBarComponent} from "../project-view-bar/project-view-bar.component";
import {ProjectScanViewComponent} from "../project-scan-view/project-scan-view.component"; // CLI imports router
import {ProjectHistoryViewComponent} from "../project-history-view/project-history-view.component";
import {AddProjectViewComponent} from "../add-project-view/add-project-view.component";
import { MsalGuard, MsalRedirectComponent } from '@azure/msal-angular';
import { BrowserUtils } from '@azure/msal-browser';
import { HomeComponent } from '../home/home.component';
import {ProjectScanHistoryViewComponent} from "../project-scan-history-view/project-scan-history-view.component";
const routes: Routes = [
  { path: 'projects',
    component: ProjectViewBarComponent,
    canActivate: [
      MsalGuard
    ]
  },
  { path: 'project/:projectId',
    component: ProjectScanViewComponent,
    canActivate: [
      MsalGuard
    ]
  },

  { path: 'project/:projectId/history/:historySecurityScanID',
    component: ProjectScanHistoryViewComponent,
    canActivate: [
      MsalGuard
    ]
  },
  {
    path: 'addProject',
    component: AddProjectViewComponent,
    canActivate: [
      MsalGuard
  ]
  },
  {
    // Needed for handling redirect after login
    path: 'auth',
    component: MsalRedirectComponent
  },
  {
    path: '',
    component: HomeComponent
  }

]; // sets up routes constant where you define your routes


@NgModule({
  imports: [RouterModule.forRoot(routes,
    {
      // Don't perform initial navigation in iframes or popups
      initialNavigation: !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup() ? 'enabledNonBlocking' : 'disabled' // Set to enabledBlocking to use Angular Universal
    })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
