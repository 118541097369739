<div class="root">
  <form [formGroup]="projectForm" (ngSubmit)="addProject()">
    <div>
      <mat-form-field appearance="fill">
        <mat-label>Domain Name</mat-label>
        <input matInput formControlName="domainName">
        <mat-error *ngIf="projectForm.get('domainName')?.invalid && projectForm.get('domainName')?.touched">
          Domain Name is required.
        </mat-error>
      </mat-form-field>
    </div>
    <div>
      <mat-form-field appearance="fill">
        <mat-label>Application Name</mat-label>
        <input matInput formControlName="applicationName">
        <mat-error *ngIf="projectForm.get('applicationName')?.invalid && projectForm.get('applicationName')?.touched">
          Application Name is required.
        </mat-error>
      </mat-form-field>
    </div>
    <div formArrayName="applicationSettings">
      <div [formGroupName]="0">
        <div>
          <mat-form-field appearance="fill">
            <mat-label>Azure DevOps Page Link</mat-label>
            <input matInput formControlName="azureDevopsPageLink">
          </mat-form-field>
        </div>
        <div>
          <mat-form-field appearance="fill">
            <mat-label>Select</mat-label>
            <mat-select formControlName="scheduleForScan">
              <mat-option value="every day">Scan every day</mat-option>
              <mat-option value="every week">Scan every week</mat-option>
              <mat-option value="every two weeks">Scan every two weeks</mat-option>
              <mat-option value="every month">Scan every month</mat-option>
            </mat-select>
            <mat-error *ngIf="getApplicationSetting(0)?.get('scheduleForScan')?.invalid && getApplicationSetting(0)?.get('scheduleForScan')?.touched">
              Please select a schedule for scan.
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>

    <button mat-raised-button color="primary" type="submit" [disabled]="!projectForm.valid">Add Project</button>
  </form>
</div>

