/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { SecurirtyScanDTO } from '../models/SecurirtyScanDTO';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable({
  providedIn: 'root',
})
export class ApplicationSecurityScanApiService {

    constructor(public readonly http: HttpClient) {}

    /**
     * @param applicationId 
     * @returns SecurirtyScanDTO Success
     * @throws ApiError
     */
    public getApiApplicationsSecurityScanLatest(
applicationId: number,
): Observable<SecurirtyScanDTO> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/Applications/{applicationID}/SecurityScan/latest',
            path: {
                'applicationID': applicationId,
            },
        });
    }

    /**
     * @param applicationId 
     * @returns SecurirtyScanDTO Success
     * @throws ApiError
     */
    public getApiApplicationsSecurityScanAll(
applicationId: number,
): Observable<Array<SecurirtyScanDTO>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/Applications/{applicationID}/SecurityScan/all',
            path: {
                'applicationID': applicationId,
            },
        });
    }

}
