<div class="top-box">
  <mat-form-field appearance="fill" floatLabel="always" class="search-bar">
    <mat-label>Enter your project name</mat-label>
    <input matInput type="text" [(ngModel)]="searchTerm" placeholder="Search Projects">
    <span matTextPrefix><mat-icon matSuffix>search</mat-icon></span>
  </mat-form-field>
    <button mat-raised-button color="primary" class="md-raised md-primary pull-right formPage" [routerLink]="['/addProject']">Add Project</button>
</div>
<div class="whole-view">
  <hr>
  <ng-container *ngIf="filteredApplications !== null; else loadingTemplate">
    <p *ngIf="filteredApplications.length === 0" class="no-applications">No projects</p>

    <div class="project-view-row" *ngFor="let application of filteredApplications">
      <mat-card class="rectangle">
        <mat-card-header>
          <mat-card-title>{{application.applicationName}}</mat-card-title>
          <button mat-icon-button>
            <mat-icon>settings</mat-icon>
          </button>
        </mat-card-header>
        <mat-card-content class="bottom">
          <button mat-raised-button color="primary"  [routerLink]="['/project', application.applicationId]" [state]="application" ariaCurrentWhenActive="page">View Details</button>
          <div class="state-box" >
            <div class="project-history-row-item" *ngIf="true">
              <mat-card class="card-items">
                <mat-card-title class="card-contents"><div class="ssl-score">SSL Labs Score</div></mat-card-title>
                <mat-card class="score-items">
                  <div class="score-container">
                    <div [ngClass]="endPointGradeColor(application.securityScanPriamryGrade.sslLabsGrade)">{{application.securityScanPriamryGrade.sslLabsGrade ?? 'X'}} </div>
                  </div>
                </mat-card>
              </mat-card>
            </div>
          </div>
          <div class="state-box" >
            <div class="project-history-row-item">
              <mat-card class="card-items">
                <mat-card-title class="card-contents">SecurityHeaders Score</mat-card-title>
                <mat-card class="score-items">
                  <div class="score-container">
                    <div [ngClass]="endPointGradeColor(application.securityScanPriamryGrade.securityHeadersGrade)">{{application.securityScanPriamryGrade.securityHeadersGrade  ?? 'X'}} </div>
                  </div>
                </mat-card>
              </mat-card>
            </div>
          </div>
          <div class="state-box" >
            <div class="project-history-row-item">
              <mat-card class="card-items">
                <mat-card-title class="card-contents"><div class="ssl-score">AverageScore</div></mat-card-title>
                <mat-card class="score-items">
                  <div class="score-container">
                    <div [ngClass]="endPointGradeColor(AverageGrade(application.securityScanPriamryGrade.securityHeadersGrade, application.securityScanPriamryGrade.sslLabsGrade))">{{AverageGrade(application.securityScanPriamryGrade.securityHeadersGrade, application.securityScanPriamryGrade.sslLabsGrade)}} </div>
                  </div>
                </mat-card>
              </mat-card>
            </div>

          </div>
          <div> Tested {{calculateTimeAgo(application.securityScanPriamryGrade.scanPerformed)}}</div>

          <div *ngIf="!application.performingScan">
            <button mat-raised-button color="primary" class="md-raised md-primary pull-right formPage" [disabled]="application.disabled" (click)="startNewScan(application)">Start scan</button>
          </div>
          <div *ngIf="application.performingScan">
            <button mat-raised-button color="warn" class="md-raised md-warn pull-right formPage" [disabled]="application.disabled" (click)="stopScan(application)">Stop Scan</button>
          </div>
        </mat-card-content>
      </mat-card>
    </div>

  </ng-container>
</div>

<ng-template #loadingTemplate>
  <div class="no-applications">
  <mat-spinner > </mat-spinner>
  <p>Loading...</p>
    </div>
</ng-template>
