import { Component, OnInit } from '@angular/core';
import { ApplicationService } from '../services/application.service';
import {ApplicationsApiService, ScansApiService, SecurityScanApiService} from "../api/generated";
import {Application} from '../api/generated/models/Application'
import {SecurityScanPrimaryGrade} from "../api/generated/models/SecurityScanPrimaryGrade";

type ExtendedApplication = Application & {disabled: boolean, performingScan: boolean, loadChild: boolean, securityScanPriamryGrade: SecurityScanPrimaryGrade};

@Component({
  selector: 'app-project-view-bar',
  templateUrl: './project-view-bar.component.html',
  styleUrls: ['./project-view-bar.component.css']
})

export class ProjectViewBarComponent implements OnInit {
  searchTerm: string = '';
  applications: Array<ExtendedApplication> | null = null;
  app: Array<Application> = [];
  get filteredApplications(): Array<ExtendedApplication> | null {
    if (this.searchTerm) {
      return this.applications?.filter(app => app.applicationName?.toLowerCase().includes(this.searchTerm.toLowerCase())) ?? null;
    }
    return this.applications;
  }
  constructor(private applicationService: ApplicationService, private applicationApiService: ApplicationsApiService, private scanApiService: ScansApiService) {

  }

  AverageGrade(grade1: string | undefined | null, grade2: string | undefined | null): string {
    // Define a map for the grade system
    const gradeSystem = {
      'A+': 7,
      'A': 6,
      'B': 5,
      'C': 4,
      'D': 3,
      'E': 2,
      'F': 1
    };

    // Define the inverse map to get back grades from scores
    const gradeSystemInverse = {
      7: 'A+',
      6: 'A',
      5: 'B',
      4: 'C',
      3: 'D',
      2: 'E',
      1: 'F'
    };

    // Default score in case of undefined or null
    const defaultScore = 4; // Default to 'C'

    // Convert the grades to scores
    // @ts-ignore
    const score1 = grade1 !== null && grade1 !== undefined ? gradeSystem[grade1] : defaultScore;
    // @ts-ignore
    const score2 = grade2 !== null && grade2 !== undefined ? gradeSystem[grade2] : defaultScore;

    // Compute the average score
    const avgScore = Math.round((score1 + score2) / 2);

    // Map the average score back to a grade
    // @ts-ignore
    const avgGrade = gradeSystemInverse[avgScore];

    return avgGrade;
  }


  endPointGradeColor(grade: string| undefined| null): string{
    var color = "";
    if(grade == null){
      return color = "rating_x";
    }
    if(grade == "N/A"){
      return color = "rating_NA";
    }
    if (grade == "A+" || grade == "A"){
      color = "rating_g";
    } else if (grade == "B" || grade == "C" || grade == "D" || grade == "E"){
      color = "rating_a";
    } else {
      color = "rating_r";
    }
    return color;
  }
  ngOnInit(): void {
    this.getAllApplications();
  }
  getAllApplications() {
    this.applicationApiService.getApiApplications().subscribe(
      applications => {
        this.applications = applications.map(app => {
          return {
            ...app,
            disabled: false,
            performingScan: false,
            loadChild: false,
            securityScanPriamryGrade: {
              securityHeadersGrade: "N/A",
              sslLabsGrade: "N/A"
            }
          } as ExtendedApplication;
        });
        this.loadPrimaryScores(this.applications);
      }
    );
  }

  loadPrimaryScores(applications: Array<ExtendedApplication>) {
    applications.forEach(app => {
      // Check if applicationId is defined before making the API call
      if (app.applicationId !== undefined) {
        this.scanApiService.getApiScansGetLastMainScore(app.applicationId).subscribe(
          (primaryGrade: SecurityScanPrimaryGrade) => {
            app.securityScanPriamryGrade = primaryGrade;
          },
          (error: any) => {
            app.securityScanPriamryGrade.securityHeadersGrade = "N/A";
            app.securityScanPriamryGrade.sslLabsGrade = "N/A";
            app.securityScanPriamryGrade.scanPerformed = null;
          }
        );
      } else {
        console.warn('applicationId is undefined for app:', app);
      }
    });
  }


  calculateTimeAgo(scanPerformed: string| null| undefined): string {
    if(scanPerformed == null || scanPerformed == undefined){
      return "never";
    }
    const scanDate = new Date(scanPerformed);
    const now = new Date();
    const diffInMilliseconds = now.getTime() - scanDate.getTime();
    const diffInHours = Math.floor(diffInMilliseconds / (1000 * 60 * 60));
    const diffInDays = Math.floor(diffInHours / 24);

    if (diffInHours < 24) {
      return `${diffInHours} hour(s) ago`;
    } else {
      return `${diffInDays} day(s) ago`;
    }
  }


  stopScan(application: ExtendedApplication) {
    application.disabled = true;

    let applicationId: string;
    if(application.applicationId == null){
      application.disabled = false;
      throw Error();
    }

    applicationId = application.applicationId.toString();

    this.applicationService.stopScan(applicationId).subscribe(
      (status: number) => {
        console.log(`Scan stopped with status code: ${status}`);

        application.performingScan = false;
        application.disabled = false;
      },
      (error: any) => {
        console.error(error);
      }
    );

  }
  startNewScan(application: ExtendedApplication) {
    application.disabled = true;

    let applicationId: string;
    if(application.applicationId == null){
      application.disabled = false;
      throw Error();
    }
    applicationId = application.applicationId.toString();

    this.applicationService.startNewScan(applicationId).subscribe(
      (status: number) => {
        console.log(`Scan started with status code: ${status}`);

        application.performingScan = true;
        application.disabled = false;
      },
      (error: any) => {
        console.error(error);
      }
    );

  }
  addProject(){

  }


}
