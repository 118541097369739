<mat-toolbar class="navigation-bar">
  <picture>
    <img class="interpulse-logo" src="../../assets/images/interpulse_logo.png">
  </picture>
  <mat-card-actions class="navigation-option" routerLink="">Dashboard</mat-card-actions>
  <mat-card-actions class="navigation-option" routerLink="projects">Projects</mat-card-actions>
  <mat-card-actions class="navigation-option" routerLink="">Scan Settings</mat-card-actions>
  <mat-card-title class="scanner-title">Security Scanner</mat-card-title>
  <mat-card-title class="login-button">
    <button mat-raised-button *ngIf="!loginDisplay" (click)="login()">Login</button>
    <button mat-raised-button color="accent" *ngIf="loginDisplay" (click)="logout()">Logout</button>
  </mat-card-title>
</mat-toolbar>
