import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpStatusCode } from '@angular/common/http';
import { map, Observable } from 'rxjs';
import {environment} from "../../environments/environment";
@Injectable({
  providedIn: 'root'
})
export class ApplicationService {

  baseUrl = environment.apiBasePath;

  constructor(private http: HttpClient) {

  }
  startNewScan(applicationId: string): Observable<number> {
    const startScanUrl = `${this.baseUrl}/api/StartStopScan/${applicationId}/startscan`;
    console.log(startScanUrl);
    return this.http.post<any>(startScanUrl, null, {
      headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' }),
      observe: 'response'
    }).pipe(
      map((response: HttpResponse<any>) => {
        return response.status;
      })
    );
  }

  stopScan(applicationId: string): Observable<number> {
    const startScanUrl = `${this.baseUrl}/api/StartStopScan${applicationId}/stopscan`;
    console.log(startScanUrl);
    return this.http.post<any>(startScanUrl, null, {
      headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' }),
      observe: 'response'
    }).pipe(
      map((response: HttpResponse<any>) => {
        return response.status;
      })
    );
  }
}
