import { Component, OnInit } from '@angular/core';
import { Application } from '../api/generated/models/Application';
import { ApplicationsApiService, ApplicationSecurityScanApiService } from '../api/generated';
import { ActivatedRoute, Router } from '@angular/router';
import { SecurityScan } from '../api/generated/models/SecurityScan';
import {MatTabChangeEvent} from "@angular/material/tabs";

@Component({
  selector: 'app-project-scan-view',
  templateUrl: './project-scan-view.component.html',
  styleUrls: ['./project-scan-view.component.css']
})
export class ProjectScanViewComponent implements OnInit {
  projectId: string | null = null;
  application: Application | null  = null;
  securityScan: SecurityScan | null = null;
  selectedIndex: number = 0;
  hide = false;

  changeHide(val: boolean) {
    this.hide = val;
  }

  constructor(
    private applicationSecurityScanApiService: ApplicationSecurityScanApiService,
    private router: Router,
    private route: ActivatedRoute,
    private applicationApiService: ApplicationsApiService
  ) {
    this.projectId = this.route.snapshot.paramMap.get('projectId');
    console.log(this.projectId);

    if(this.router.getCurrentNavigation() != null) {
      // @ts-ignore
      const projectX = this.router.getCurrentNavigation().extras.state;
      if (projectX == null) {
        if (this.projectId != null) {
          const numberProjectID: number = +this.projectId;
          this.applicationApiService.getApiApplications1(numberProjectID).subscribe(
            application => {
              this.application = application;
              console.log('Application loaded:', this.application);
              this.loadSecurityScan();
            },
            error => {
              console.error(error);
              throw new Error('Error retrieving application.');
            }
          );
        } else {
          throw new Error('Project ID is null.');
        }
      } else {
        this.application = projectX;
        this.loadSecurityScan();
      }
    }else{
      throw new Error('router.getCurrentNavigation is null.');
    }
  }

  onTabChanged(event: any) {
    this.selectedIndex = event.index;
  }
  ngOnInit(): void {
  }

  protected getSecurityScanID(): number{
    if (this.securityScan?.securityScanId == null){
      throw Error();
    }
    return this.securityScan.securityScanId;
}
  private loadSecurityScan(): void {
    if (this.application != null) {
      if (this.application.applicationId) {
        this.applicationSecurityScanApiService.getApiApplicationsSecurityScanLatest(this.application.applicationId).subscribe(
          securityScan => {
            this.securityScan = securityScan;
            console.log('Security scan loaded:', this.securityScan);
            console.log('Application loaded:', this.application);
          },
          error => {
            console.error(error);
            throw new Error('Error retrieving security scan.');
          }
        );
      }
    }
  }
}
