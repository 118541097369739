/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { ActivatedScanDTO } from '../models/ActivatedScanDTO';
import type { ScanApplicationDTO } from '../models/ScanApplicationDTO';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable({
  providedIn: 'root',
})
export class ScanApplicationApiService {

    constructor(public readonly http: HttpClient) {}

    /**
     * @param applicationSettingsId 
     * @returns ActivatedScanDTO Success
     * @throws ApiError
     */
    public getApiScanApplicationActivated(
applicationSettingsId: number,
): Observable<Array<ActivatedScanDTO>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/ScanApplication/activated/{applicationSettingsId}',
            path: {
                'applicationSettingsId': applicationSettingsId,
            },
        });
    }

    /**
     * @param applicationSettingsId 
     * @param requestBody 
     * @returns ActivatedScanDTO Success
     * @throws ApiError
     */
    public putApiScanApplicationUpdateActivated(
applicationSettingsId: number,
requestBody?: Array<ActivatedScanDTO>,
): Observable<Array<ActivatedScanDTO>> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/api/ScanApplication/updateActivated/{applicationSettingsId}',
            path: {
                'applicationSettingsId': applicationSettingsId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns ScanApplicationDTO Success
     * @throws ApiError
     */
    public getApiScanApplication(): Observable<Array<ScanApplicationDTO>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/ScanApplication',
        });
    }

    /**
     * @param id 
     * @returns ScanApplicationDTO Success
     * @throws ApiError
     */
    public getApiScanApplication1(
id: number,
): Observable<ScanApplicationDTO> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/ScanApplication/{id}',
            path: {
                'id': id,
            },
        });
    }

}
