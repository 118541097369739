<div class="whole-view-settings">
  <div class="settings-buttons">
    <div class="button">
      <button (click)="changeSettingsIndex(0)">GENERAL</button>
    </div>
    <div class="button">
      <button (click)="changeSettingsIndex(1)">NOTIFICATIONS</button>
    </div>
  </div>
  <div class="settings">
  <div class="general-settings" *ngIf="settingsIndex ===0">
    <mat-card class="settings-card">
      <mat-card-header>
        <mat-card-title>Project Domain Name</mat-card-title>
        <mat-card-subtitle>Change the domain name of your project</mat-card-subtitle>
      </mat-card-header>
      <hr>
      <mat-card-content>
        <div>current: <a href="https://{{application?.domainName}}">{{application?.domainName}}</a></div>
        <div [formGroup]="domainForm">
          <mat-form-field>
            <mat-label>Domain Name</mat-label>
            <input matInput type="text" formControlName="domainName">
            <mat-error *ngIf="domainForm.controls['domainName'].hasError('pattern')">Please enter a valid domain name.</mat-error>
          </mat-form-field>
        </div>
      </mat-card-content>
      <mat-card-actions>
        <button class="submit-button" mat-raised-button color="primary" [disabled]="domainForm.controls['domainName'].invalid" (click)="changeDomainName()">Change</button>
      </mat-card-actions>
    </mat-card>

    <mat-card class="settings-card">
      <mat-card-header>
        <mat-card-title>Activate & Deactivate Tools</mat-card-title>
        <mat-card-subtitle>Activate or deactivate scan tools for a project.</mat-card-subtitle>
      </mat-card-header>
      <hr>
      <mat-card-content>
        <div *ngFor="let activatedScan of activatedScans" class="activated-scans">
          <mat-slide-toggle color="blue"[(ngModel)]="activatedScan.activated">{{getScanApplicationName(activatedScan.scanApplicationId)}}</mat-slide-toggle>
        </div>
        <button class="submit-button" mat-raised-button color="primary" [disabled]="scanApplications?.length === 0" (click)="updateActivatedScan()">Change</button>

      </mat-card-content>
    </mat-card>

    <mat-card class="settings-card">
      <mat-card-header>
        <mat-card-title>Automated test Frequency</mat-card-title>
        <mat-card-subtitle>Change the frequency of the automated test and the current associated schedule</mat-card-subtitle>
      </mat-card-header>
      <hr>
      <mat-card-content>
        <div>
          Current frequency: {{applicationgSettings?.frequencyScan}}
        </div>
        <div>
          Next scans will be on: {{applicationgSettings?.scheduleForScan| date: 'medium'}}
        </div>

        <form [formGroup]="scheduleForm">
          <mat-form-field appearance="fill">
            <mat-label>Select</mat-label>
            <mat-select formControlName="scheduleForScan">
              <mat-option value="every day">Scan every day</mat-option>
              <mat-option value="every week">Scan every week</mat-option>
              <mat-option value="every two weeks">Scan every two weeks</mat-option>
              <mat-option value="every month">Scan every month</mat-option>
            </mat-select>
            <mat-error *ngIf="scheduleForm.controls['scheduleForScan'].invalid && scheduleForm.controls['scheduleForScan'].touched">
              Please select a schedule for scan.
            </mat-error>
          </mat-form-field>
          <button class="submit-button" mat-raised-button color="primary" [disabled]="scheduleForm.invalid" (click)="changeScheduleForScan()">Change</button>
        </form>
      </mat-card-content>

    </mat-card>


    <mat-card class="settings-card">
      <mat-card-header>
        <mat-card-title>AzureDevops Link</mat-card-title>
        <mat-card-subtitle>Change the link to Azure DevOps</mat-card-subtitle>
      </mat-card-header>
      <hr>
      <mat-card-content>
        <div>current: <a href="{{applicationgSettings?.azureDevopsPageLink}}">{{applicationgSettings?.azureDevopsPageLink}}</a></div>
        <div [formGroup]="azureForm">
          <mat-form-field>
            <mat-label>Azure DevOps Link</mat-label>
            <input matInput type="text" formControlName="azureLink">
            <mat-error *ngIf="azureForm.controls['azureLink'].hasError('pattern')">Please enter a valid domain name.</mat-error>
          </mat-form-field>
        </div>
      </mat-card-content>
      <mat-card-actions>
        <button class="submit-button" mat-raised-button color="primary" [disabled]="azureForm.controls['azureLink'].invalid" (click)="changeAzureDevOpsLink()">Change</button>
      </mat-card-actions>
    </mat-card>

    <mat-card class="settings-card">
      <mat-card-header>
        <mat-card-title>Delete Project</mat-card-title>
        <mat-card-subtitle>Type the application name to confirm deletion</mat-card-subtitle>
      </mat-card-header>
      <hr>
      <mat-card-content>
        <form>
          <mat-form-field appearance="outline">
            <mat-label>Application Name</mat-label>
            <input matInput type="text" [(ngModel)]="inputApplicationName" name="applicationName" required (input)="validateInput()">
          </mat-form-field>
          <div>
          <button mat-raised-button color="warn" [disabled]="!isNameMatching" (click)="confirmDeletion()">Confirm Deletion</button>
          </div>
        </form>
      </mat-card-content>
    </mat-card>


  </div>
  <div class="notifactions-settings" *ngIf="settingsIndex ===1">
    <mat-card class="settings-card">
      <mat-card-content>Simple card</mat-card-content>
    </mat-card>
    <mat-card class="settings-card">
      <mat-card-content>Simple card</mat-card-content>
    </mat-card>

  </div>


  </div>

</div>
