import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatIcon, MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ProjectViewBarComponent } from './project-view-bar/project-view-bar.component';
import { NavigationBarComponent } from './navigation-bar/navigation-bar.component';
import { SslLabsViewBoxComponent } from './ssl-labs-view-box/ssl-labs-view-box.component';
import {AppRoutingModule} from "./app-routing/app-routing.module";
import {MatButtonModule} from "@angular/material/button";
import { ProjectScanViewComponent } from './project-scan-view/project-scan-view.component';
import {MatTabsModule} from "@angular/material/tabs";
import {RouterLink} from "@angular/router";

import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { IPublicClientApplication, PublicClientApplication, InteractionType } from '@azure/msal-browser';
import {
  MsalGuard, MsalInterceptor, MsalBroadcastService, MsalInterceptorConfiguration, MsalModule, MsalService,
  MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalGuardConfiguration, MsalRedirectComponent, ProtectedResourceScopes
} from '@azure/msal-angular';

import { msalConfig, loginRequest, protectedResources } from './auth-config';
import { HomeComponent } from './home/home.component';
import {MatTableModule} from "@angular/material/table";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import { SecurityHeadersBoxComponent } from './security-headers-box/security-headers-box.component';
import { AddProjectViewComponent } from './add-project-view/add-project-view.component';
import { ProjectSettingsViewComponent } from './project-settings-view/project-settings-view.component';
import { ProjectHistoryViewComponent } from './project-history-view/project-history-view.component';
import {MatInputModule} from "@angular/material/input";
import {MatSelectModule} from "@angular/material/select";
import {MatSnackBarModule} from '@angular/material/snack-bar';
import { ProjectScanHistoryViewComponent } from './project-scan-history-view/project-scan-history-view.component';
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";

/**
 * Here we pass the configuration parameters to create an MSAL instance.
 * For more info, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/configuration.md
 */
export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication(msalConfig);
}

/**
 * MSAL Angular will automatically retrieve tokens for resources
 * added to protectedResourceMap. For more info, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/initialization.md#get-tokens-for-web-api-calls
 */
export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string | ProtectedResourceScopes> | null>();


  protectedResourceMap.set(protectedResources.apiTodoList.endpoint, [
    {
      httpMethod: 'GET',
      scopes: [...protectedResources.apiTodoList.scopes.read]
    },
    {
      httpMethod: 'POST',
      scopes: [...protectedResources.apiTodoList.scopes.write]
    },
    {
      httpMethod: 'PUT',
      scopes: [...protectedResources.apiTodoList.scopes.write]
    },
    {
      httpMethod: 'DELETE',
      scopes: [...protectedResources.apiTodoList.scopes.write]
    }
  ]);

  return {
    interactionType: InteractionType.Popup,
    protectedResourceMap,
  };
}

/**
 * Set your default interaction type for MSALGuard here. If you have any
 * additional scopes you want the user to consent upon login, add them here as well.
 */
export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: loginRequest
  };
}

@NgModule({
  declarations: [
    AppComponent,
    ProjectViewBarComponent,
    NavigationBarComponent,
    SslLabsViewBoxComponent,
    ProjectScanViewComponent,
    HomeComponent,
    SecurityHeadersBoxComponent,
    AddProjectViewComponent,
    ProjectSettingsViewComponent,
    ProjectHistoryViewComponent,
    ProjectScanHistoryViewComponent,
  ],
    imports: [
        BrowserModule, AppRoutingModule, HttpClientModule, BrowserAnimationsModule, MatSlideToggleModule, MatCardModule, MatIconModule, MatToolbarModule, MatButtonModule, MatTabsModule, RouterLink, MatTableModule,
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        MatButtonModule,
        MatToolbarModule,
        MatTableModule,
        MatCardModule,
        MatFormFieldModule,
        MatCheckboxModule,
        MatIconModule,
        HttpClientModule,
        FormsModule,
        MsalModule, MatInputModule, MatSelectModule, ReactiveFormsModule,
        MatSnackBarModule, MatProgressSpinnerModule
    ],
  providers: [ {
    provide: HTTP_INTERCEPTORS,
    useClass: MsalInterceptor,
    multi: true
  },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    MatIcon],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule { }

