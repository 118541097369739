<div class="12u" *ngIf="securityHeaderInterface !== null">
  <div class="reportSection push-top">
    <div class="reportTitle">Security Report Summary</div>
    <div class="reportBody">
      <div class="row">
        <div class="report-u">
          <div class="score">
            <div class="{{determineColorGrade(securityHeaderInterface.summary.grade)}}"><span>{{securityHeaderInterface.summary.grade}}</span></div>
          </div>
        </div>
        <div class="10u push-left">
          <table class="reportTable">
            <colgroup><col class="col1">
              <col class="col2">
            </colgroup><tbody>
          <tr class="tableRow">
            <th class="tableLabel">Site:</th>
            <td class="tableCell">
              <a href="{{securityHeaderInterface.summary.site}}" target="_blank" rel="nofollow noreferrer noopener">
                {{securityHeaderInterface.summary.site}}</a>
            </td>
          </tr>
          <tr class="tableRow">
            <th class="tableLabel">IP Address:</th>
            <td class="tableCell">
              {{securityHeaderInterface.summary.ip}}</td>
          </tr>
          <tr class="tableRow">
            <th class="tableLabel">Report Time:</th>
            <td class="tableCell">
              {{securityHeaderInterface.summary.timestamp}}
            </td>
          </tr>
          <tr class="tableRow">
            <th class="tableLabel">Headers: </th>
            <td class="tableCell">
              <ul class="pillList">
                <li *ngFor="let header of headers" class="headerItem pill pill-{{header.value}} ">
                  <i class="fa fa-{{determineCheckOrTimes(header.value)}}"> </i> {{header.key}}
                </li>
              </ul>
            </td>
          </tr>
          <tr class="tableRow" *ngIf="securityHeaderInterface.summary.gradeCap">
            <th class="tableLabel">Warning:</th>
            <td class="tableCell">
              Grade capped at {{securityHeaderInterface.summary.gradeCap}}, please see warnings below. </td>
          </tr>
          </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <ng-container *ngIf="checkIfMissingHeadersExist(securityHeaderInterface.missingHeaders)">
    <div class="reportSection" *ngIf="securityHeaderInterface.missingHeaders !== null">
      <div class="reportTitle">Missing Headers</div>
      <div class="reportBody">
        <table class="reportTable">
          <colgroup><col class="col1"><col class="col2"></colgroup>
          <tbody>
          <tr class="tableRow" *ngFor="let missingHeader of securityHeaderInterface.missingHeaders | keyvalue">
            <th class="tableLabel table_red">{{missingHeader.key}}</th>
            <td class="tableCell" [innerHTML]="missingHeader.value.missing"></td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </ng-container>
  <div class="reportSection" *ngIf="securityHeaderInterface.summary.gradeCap">
    <div class="reportTitle">Warnings</div>
    <div class="reportBody">
      <table class="reportTable">
        <colgroup><col class="col1"><col class="col2"></colgroup>
        <tbody>
        <tr class="tableRow" *ngFor="let error of securityHeaderInterface.validationErrors | keyvalue">
          <th class="tableLabel table_orange">{{ error.key }} </th>
          <td class="tableCell">{{ error.value }} </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="reportSection">
    <div class="reportTitle">Raw Headers</div>
    <div class="reportBody">
      <table class="reportTable">
        <colgroup>
          <col class="col1">
          <col class="col2">
        </colgroup>
        <tbody>
        <tr class="tableRow" *ngFor="let header of securityHeaderInterface.rawHeaders">
          <th class="tableLabel table_{{header.colour}}">{{header.key}}</th>
          <td class="tableCell">{{header.value}}</td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="reportSection">
    <div class="reportTitle">Upcoming Headers</div>
    <div class="reportBody">
      <table class="reportTable">
        <colgroup><col class="col1"><col class="col2"></colgroup>
        <tbody>
        <tr class="tableRow" *ngFor="let upcomingHeader of securityHeaderInterface.upcomingHeaders | keyvalue">
          <th class="tableLabel table_blue">{{upcomingHeader.value.key}}</th>
          <td class="tableCell" [innerHTML]="upcomingHeader.value.info"></td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>



  <div class="reportSection">
    <div class="reportTitle">Additional Information</div>
    <div class="reportBody">
      <table class="reportTable">
        <colgroup><col class="col1"><col class="col2"></colgroup>
        <tbody>
        <tr class="tableRow" *ngFor="let additionalInfo of securityHeaderInterface.additionalInformation | keyvalue">
          <th class="tableLabel" [ngClass]="{'table_blue': additionalInfo.value.colour == 'blue', 'table_green': additionalInfo.value.colour === 'green'}">{{additionalInfo.key}}</th>
          <td class="tableCell" [innerHTML]="additionalInfo.value.info"></td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
