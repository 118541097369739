import {Component, Input, OnInit} from '@angular/core';
import {ScansApiService} from "../api/generated";
import {SslLabsInterface, Endpoint, Protocol} from "../models/sslLabsScanInterface"

@Component({
  selector: 'app-ssl-labs-view-box',
  templateUrl: './ssl-labs-view-box.component.html',
  styleUrls: ['./ssl-labs-view-box.component.css']
})
export class SslLabsViewBoxComponent implements OnInit {
  @Input() inData: number | null | undefined;
  sslLabsScan: SslLabsInterface | undefined;
  endpoints: Array<Endpoint> | undefined =[];

    constructor(private scansApiService: ScansApiService) {
    }

  ngOnInit(): void {
    if (this.inData == null) {
      throw new Error('Input data is null.');
    }
    this.scansApiService.getApiScansLastLinked(this.inData, 1).subscribe(result => {
      if (result && result.scanResults) {
          // @ts-ignore
          var jsonData = JSON.parse(result.scanResults);
          this.sslLabsScan = jsonData as SslLabsInterface;
          if (this.sslLabsScan.endpoints) {
            this.sslLabsScan.endpoints.map(a => this.endpoints?.push(a));
          } else {
            console.log('Endpoints property is undefined.');
          }
      } else {
        console.log('Scan results are null or undefined.');
      }
    });
  }

  calculateProtocolScore(endpoint: Endpoint): string{
      var certificates = endpoint.details.protocols;
      var maxWidth = 67;
      var score = calculateProtocolScore(certificates);
      maxWidth = (maxWidth/100) * score;

      return maxWidth.toString();
  }

  calculateProtocolColorGrade(endpoint: Endpoint): string{
    var certificates = endpoint.details.protocols;
    var score = calculateProtocolScore(certificates);
    return this.colorScoreCalculator(score);
  }

  colorScoreCalculator(score: number): string{
    var color = "";
    if (score >= 80){
      color = "chartBar_g";
    } else if (score >= 50){
      color = "chartBar_a";
    } else {
      color = "chartBar_r";
    }
    return color;
  }
  endPointGradeColor(endpointGrade: string): string{
    var color = "";
    if (endpointGrade == "A+" || endpointGrade == "A"){
      color = "rating_g";
    } else if (endpointGrade == "B" || endpointGrade == "C" || endpointGrade == "D" || endpointGrade == "E"){
      color = "rating_a";
    } else {
      color = "rating_r";
    }
    return color;
  }

  caculateCipherScore(endpoint: Endpoint): string{
    var maxWidth = 67;
    var score = this.calculateCipherStrengthScore(endpoint);
    maxWidth = (maxWidth/100) * score;
    return maxWidth.toString();
  }
  calculateCipherColorGrade(endpoint: Endpoint): string{
    var score = this.calculateCipherStrengthScore(endpoint);
    return this.colorScoreCalculator(score);
  }

  calculateCipherStrengthScore(endpoint: Endpoint): number {
    let bestScore = 0;
    let worstScore = 0;

      for (const suite of endpoint.details.suites) {
        for (const list of suite.list) {
          let score = 0;

          if (list.cipherStrength >= 256) {
            score = 100;
          } else if (list.cipherStrength < 256) {
            score = 80;
          } else if (list.cipherStrength < 128) {
            score = 20;
          } else {
            score = 0;
          }

          if (score > bestScore) {
            bestScore = score;
          }

          if (score < worstScore || worstScore === 0) {
            worstScore = score;
          }
        }



    }return (bestScore + worstScore) / 2;
  }

  calculateKeyScore(): string{
    var maxWidth = 67;
    var score = this.calculateKeyExchangeScore(this.sslLabsScan);
    maxWidth = (maxWidth/100) * score;
    return maxWidth.toString();
  }
  caculateKeyColorGrade(): string{
    var score = this.calculateKeyExchangeScore(this.sslLabsScan);
    return this.colorScoreCalculator(score);
  }

  calculateKeyExchangeScore(sslData: SslLabsInterface | undefined): number {
    if (sslData == null) {
      throw new Error('Input data is null.');
    }

    let bestScore = 0;

    for (const cert of sslData.certs) {
      let score = 0;
      if (cert.keyKnownDebianInsecure) {//Weak key (Debian OpenSSL flaw)
        score = 0;
      } else if (cert.keySize < 512) {//Key or DH parameter strength < 512 bits
        score = 20;
      } else if (cert.keySize < 1024) {//Key or DH parameter strength < 1024 bits
        score = 40;
      } else if (cert.keySize < 2048) {//Key or DH parameter strength < 2048 bits
        score = 80;
      } else if (cert.keySize < 4096) {//Key or DH parameter strength < 4096 bits
        score = 90;
      } else if (cert.keySize >= 4096) { //Key or DH parameter strength >= 4096 bits
        score = 100;
      }

      if (score > bestScore) {
        bestScore = score;
      }
    }

    return bestScore;
  }


  calculateCertificateScore(): string{
    var maxWidth = 67;
    var score = this.isSslSecure(this.sslLabsScan);
    if(score){
      return maxWidth.toString();
    }else{
      return "0";
    }
  }

  isSslSecure(sslData: SslLabsInterface| undefined): boolean {
      if (sslData == null) {
        throw new Error('Input data is null.');
      }
    // Domain name mismatch !!CURRENTlY BROKEN!!
    let domainNameMismatch = sslData.host !== sslData.endpoints[0].ipAddress;
    if(domainNameMismatch) {
     // console.log('Domain name mismatch');
    }domainNameMismatch = false;

    // Certificate not yet valid or Certificate expired
    const currentTime = Date.now();
    const notYetValid = sslData.certs.some(cert => cert.notBefore > currentTime);
    if(notYetValid) {
      console.log('Certificate not yet valid');
    }

    const expired = sslData.certs.some(cert => cert.notAfter < currentTime);
    if(expired) {
      console.log('Certificate expired');
    }

    // Use of a self-signed certificate !!CURRENTlY BROKEN!!
    let selfSigned = sslData.certs.some(cert => cert.issuerSubject == cert.subject);
    if(selfSigned) {
      //console.log('Use of a self-signed certificate');
    }selfSigned = false;

    // Use of a certificate that is not trusted (unknown CA or some other validation error) !!CURRENTlY BROKEN!!
    let untrusted = sslData.endpoints.some(endpoint =>
      endpoint.details.certChains.some(chain =>
        chain.trustPaths.some(trustPath =>
          trustPath.trust.some(trust => !trust.isTrusted)
        )
      )
    );
    if(untrusted) {
      //console.log('Use of a certificate that is not trusted');
    } untrusted = false;

    // Use of a revoked certificate !!CURRENTlY BROKEN!!
    let revoked = sslData.certs.some(cert => cert.revocationStatus !== 0);
    if(revoked) {
      //console.log('Use of a revoked certificate');
    } revoked = false;

    // Insecure certificate signature (MD2 or MD5)
    const insecureSignature = sslData.certs.some(cert => ["md2", "md5"].includes(cert.sigAlg.toLowerCase()));
    if(insecureSignature) {
      console.log('Insecure certificate signature');
    }

    // Insecure key
    const insecureKey = sslData.certs.some(cert => cert.keyStrength < 2048); // For example, 2048-bit keys are generally considered secure as of the time of writing.
    if(insecureKey) {
      console.log('Insecure key');
    }

    return !(domainNameMismatch || notYetValid || expired || selfSigned || untrusted || revoked || insecureSignature || insecureKey);

  }



}
function calculateProtocolScore(protocols: Protocol[]): number {
  let bestScore = 0;
  let worstScore = 0;

  for (const protocol of protocols) {
    let score = 0;
    switch (protocol.name +" "+ protocol.version) {

      case "SSL 2.0":
        score = 0;
        break;
      case "SSL 3.0":
        score = 80;
        break;
      case "TLS 1.0":
        score = 90;
        break;
      case "TLS 1.1":
        score = 95;
        break;
      case "TLS 1.2":
        score = 100;
        break;
      default:
        score = 100;
        break;
    }

    if (score > bestScore) {
      bestScore = score;
    }

    if (score < worstScore || worstScore === 0) {
      worstScore = score;
    }
  }

  return (bestScore + worstScore) / 2;
}
