<div class="title-block">
  <div class="title-box">
    <h1 class="Project name"> {{application?.applicationName}}</h1>
    <mat-tab-group >
      <mat-tab>
        <ng-template mat-tab-label>
          <div routerLink="/project/{{application?.applicationId}}">OVERVIEW</div>
        </ng-template>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <div routerLink="/project/{{application?.applicationId}}">HISTORY</div>
        </ng-template>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <div routerLink="/project/{{application?.applicationId}}">SETTINGS</div>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  </div>
  <hr>
</div>
<div class="whole-view">
<div class="scan-view" *ngIf="selectedIndex === 0">
  <div>
    <mat-card class="sslLabs-box">
      <mat-card-header> SSL Labs</mat-card-header>
      <hr>
      <mat-card-content class="ssl-content" *ngIf="securityScan!=null">
        <app-ssl-labs-view-box [inData]="getSecurityScanID()"></app-ssl-labs-view-box>
      </mat-card-content>
      <mat-card-footer class="ssl-footer">
        <button> View more</button>
      </mat-card-footer>
    </mat-card>
  </div>
  <div>
    <mat-card class="sslLabs-box">
      <mat-card-header> Security Header</mat-card-header>
      <hr>
      <mat-card-content class="ssl-content" *ngIf="securityScan!=null">
        <app-security-headers-box [inData]="getSecurityScanID()"></app-security-headers-box>
      </mat-card-content>
      <mat-card-footer class="ssl-footer">
        <button> View more</button>
      </mat-card-footer>
    </mat-card>
  </div>
</div>
<div class= "history-view" *ngIf="selectedIndex === 1">
  <app-project-history-view [inData]="application"></app-project-history-view>
</div>

<div class="settings-view" *ngIf="selectedIndex === 2">
  <!-- settings-view content here -->
</div>
</div>
