/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { Scan } from '../models/Scan';
import type { ScanDTO } from '../models/ScanDTO';
import type { SecurityScanPrimaryGrade } from '../models/SecurityScanPrimaryGrade';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable({
  providedIn: 'root',
})
export class ScansApiService {

    constructor(public readonly http: HttpClient) {}

    /**
     * @returns Scan Success
     * @throws ApiError
     */
    public getApiScans(): Observable<Array<Scan>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/Scans',
        });
    }

    /**
     * @param requestBody
     * @returns Scan Success
     * @throws ApiError
     */
    public postApiScans(
requestBody?: Scan,
): Observable<Scan> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/Scans',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param securityScanId
     * @param scanApplicationId
     * @returns Scan Success
     * @throws ApiError
     */
    public getApiScansLastLinked(
securityScanId: number,
scanApplicationId: number,
): Observable<Scan> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/Scans/lastLinked/{securityScanId}/{scanApplicationID}',
            path: {
                'securityScanId': securityScanId,
                'scanApplicationID': scanApplicationId,
            },
        });
    }

    /**
     * @param securityScanId
     * @returns ScanDTO Success
     * @throws ApiError
     */
    public getApiScansLinked(
securityScanId: number,
): Observable<Array<ScanDTO>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/Scans/Linked/{securityScanId}',
            path: {
                'securityScanId': securityScanId,
            },
        });
    }

    /**
     * @param securityScanId
     * @returns SecurityScanPrimaryGrade Success
     * @throws ApiError
     */
    public getApiScansGetMainScore(
securityScanId: number,
): Observable<SecurityScanPrimaryGrade> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/Scans/GetMainScore/{securityScanId}',
            path: {
                'securityScanId': securityScanId,
            },
        });
    }

    /**
     * @param applicationId
     * @returns SecurityScanPrimaryGrade Success
     * @throws ApiError
     */
    public getApiScansGetLastMainScore(
applicationId: number,
): Observable<SecurityScanPrimaryGrade> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/Scans/GetLastMainScore/{applicationId}',
            path: {
                'applicationId': applicationId,
            },
        });
    }

    /**
     * @param id
     * @returns Scan Success
     * @throws ApiError
     */
    public getApiScans1(
id: number,
): Observable<Scan> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/Scans/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public putApiScans(
id: number,
requestBody?: Scan,
): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/api/Scans/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id
     * @returns any Success
     * @throws ApiError
     */
    public deleteApiScans(
id: number,
): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/api/Scans/{id}',
            path: {
                'id': id,
            },
        });
    }

}
