/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { Application } from '../models/Application';
import type { ApplicationDTO } from '../models/ApplicationDTO';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable({
  providedIn: 'root',
})
export class ApplicationsApiService {

    constructor(public readonly http: HttpClient) {}

    /**
     * @returns Application Success
     * @throws ApiError
     */
    public getApiApplications(): Observable<Array<Application>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/Applications',
        });
    }

    /**
     * @param requestBody 
     * @returns ApplicationDTO Success
     * @throws ApiError
     */
    public postApiApplications(
requestBody?: Application,
): Observable<ApplicationDTO> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/Applications',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id 
     * @returns Application Success
     * @throws ApiError
     */
    public getApiApplications1(
id: number,
): Observable<Application> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/Applications/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id 
     * @returns any Success
     * @throws ApiError
     */
    public deleteApiApplications(
id: number,
): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/api/Applications/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param applicationId 
     * @param newDomainName 
     * @returns any Success
     * @throws ApiError
     */
    public putApiApplicationsUpdateApplicationDomainName(
applicationId: number,
newDomainName: string,
): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/api/Applications/UpdateApplicationDomainName/{applicationId}/{newDomainName}',
            path: {
                'applicationId': applicationId,
                'newDomainName': newDomainName,
            },
        });
    }

}
