
<app-navigation-bar></app-navigation-bar>

<router-outlet></router-outlet>


<div class="container">
  <!--This is to avoid reload during acquireTokenSilent() because of hidden iframe -->
  <router-outlet *ngIf="!isIframe"></router-outlet>
</div>
