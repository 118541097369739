import {Component, Input, OnInit} from '@angular/core';
import {ScansApiService} from "../api/generated";
import {SecurityHeaderInterface, MissingHeaders} from "../models/SecurityHeaderInterfacel";

@Component({
  selector: 'app-security-headers-box',
  templateUrl: './security-headers-box.component.html',
  styleUrls: ['./security-headers-box.component.css']
})
export class SecurityHeadersBoxComponent implements OnInit {
  @Input() inData: number | null | undefined;
  securityHeaderInterface: SecurityHeaderInterface | null = null;
  headers: { key: string, value: string }[] | undefined;

  constructor(private scansApiService: ScansApiService) {
  }

  ngOnInit(): void {
    if (this.inData == null) {
      throw new Error('Input data is null.');
    }
    this.scansApiService.getApiScansLastLinked(this.inData, 2).subscribe(result => {
      if (result && result.scanResults) {
        // @ts-ignore
        var jsonData = JSON.parse(result.scanResults);
        console.log('Result:', jsonData);
        this.securityHeaderInterface = jsonData as SecurityHeaderInterface;
        console.log("data: " + this.securityHeaderInterface);
        if (this.securityHeaderInterface.summary && this.securityHeaderInterface.summary.headers) {
          this.headers = Object.entries(this.securityHeaderInterface.summary.headers)
            .map(([key, value]) => ({key, value}));
        }
      } else {
        console.log('Result is empty.');
      }
    });
  }
  checkIfMissingHeadersExist(missingHeaders: MissingHeaders): boolean {
    console.log("length: " + Object.keys(missingHeaders).length );
  return Object.keys(missingHeaders).length !== 0;
};

  determineColorGrade(grade: string): string {
    if (grade === "A+") {
      return "score_lightgreen";
    } else if (grade === "A") {
      return "score_green";
    } else if (grade == "B" || grade == "C") {
      return "score_yellow";
    } else if (grade == "D" || grade == "E") {
      return "score_orange";
    } else if (grade == "F") {
      return "score_red";
    } else if (grade == "R") {
      return "score_grey";
    } else {
      console.log("Unknown grade: " + grade);
      return "score_grey";
    }
  }
  determineCheckOrTimes(redOrGreen: string): string{
    if (redOrGreen === "red"){
      return "times";
    } else {
      return "check";
    }
  }
}
