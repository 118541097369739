import {Component, Inject, inject, OnInit} from '@angular/core';
import { FormGroup, FormArray, FormBuilder, Validators } from '@angular/forms';
import { map } from 'rxjs/operators';
import {ApplicationsApiService, ApplicationSettingsApiService, ScanApplicationApiService} from "../api/generated";
import {MatButtonModule} from "@angular/material/button";
import {MAT_SNACK_BAR_DATA, MatSnackBar, MatSnackBarModule, MatSnackBarRef} from "@angular/material/snack-bar";
import { Router } from '@angular/router';
import {ScanApplicationDTO} from "../api/generated/models/ScanApplicationDTO";
import {ActivatedScanDTO} from "../api/generated/models/ActivatedScanDTO";
@Component({
  selector: 'app-add-project-view',
  templateUrl: './add-project-view.component.html',
  styleUrls: ['./add-project-view.component.css'],
})
export class AddProjectViewComponent implements OnInit {
  projectForm: FormGroup;
  ActivatedScans: Array<ActivatedScanDTO> | undefined = undefined;

  constructor(private fb: FormBuilder, private applicationApiService: ApplicationsApiService,private applicationgSettingsApiService: ApplicationSettingsApiService, private  _snackBar: MatSnackBar, private router: Router) {
    this.projectForm = this.fb.group({
      domainName: ['', Validators.required],
      applicationName: ['', Validators.required],
      applicationSettings: this.fb.array([
        this.createApplicationSetting()
      ])
    });

    // @ts-ignore
    this.getApplicationSetting(0).get('scheduleForScan').valueChanges.pipe(
      map(value => {
        let date = new Date();
        let frequency = '';
        switch(value) {
          case 'every day':
            date.setDate(date.getDate() + 1);
            frequency = 'every day';
            break;
          case 'every week':
            date.setDate(date.getDate() + 7);
            frequency = 'every week';
            break;
          case 'every two weeks':
            date.setDate(date.getDate() + 14);
            frequency = 'every two weeks';
            break;
          case 'every month':
            date.setMonth(date.getMonth() + 1);
            frequency = 'every month';
            break;
        }

        // @ts-ignore
        this.getApplicationSetting(0).get('frequencyScan').setValue(frequency, { emitEvent: false });
        return date;
      })
    ).subscribe(newDate => {
      // @ts-ignore
      this.getApplicationSetting(0).get('nextScanDate').setValue(newDate.toISOString(), { emitEvent: false });
    });

  }

  ngOnInit(): void {
    console.log("Add project view component initialized");


  }

  openSnackBar(message: any) {
    this._snackBar.openFromComponent(SnackBarAddProjectComponent, {
      data: message,  // pass the object directly
      duration: 10 * 1000,
    });
  }


  applicationSettings(): FormArray {
    return this.projectForm.get('applicationSettings') as FormArray;
  }

  getApplicationSetting(index: number): FormGroup {
    return this.applicationSettings().at(index) as FormGroup;
  }

  createApplicationSetting(): FormGroup {
    return this.fb.group({
      azureDevopsPageLink: '',
      scheduleForScan: ['', Validators.required],
      frequencyScan: '',
      nextScanDate: '' // new form control for storing the calculated date
    });
  }

  addProject() {
    if (this.projectForm.valid) {
      var returnResponse;
      // Create a copy of the form values
      let transformedValues = { ...this.projectForm.value };

      // For each application setting, transform the scheduleForScan and frequencyScan fields
      transformedValues.applicationSettings = transformedValues.applicationSettings.map((setting: any) => ({
        ...setting,
        scheduleForScan: setting.nextScanDate, // use nextScanDate as the scheduleForScan value
        frequencyScan: setting.scheduleForScan, // use scheduleForScan as the frequencyScan value
      }));

      // Remove the nextScanDate field
      transformedValues.applicationSettings.forEach((setting: any) => {
        delete setting.nextScanDate;
      });

      console.log(JSON.stringify(transformedValues));
      this.applicationApiService.postApiApplications(transformedValues).subscribe(
        response =>{
          returnResponse = response;
          this.openSnackBar(returnResponse);// pass the response object directly
          if(response.applicationSettings != null){
            console.log("APPLICATION SETTINGS ID:"+ response.applicationSettings[0].applicationSettingsId);

          }
          this.router.navigate(['/projects']);
        },
        error => {
          console.error('There was an error!', error);
        }

      );

    } else {
      console.log('Form is not valid!');
    }
  }


}

@Component({
  selector: 'add-project-view-snack-bar',
  templateUrl: 'snack-bar-notification-project-added.html',
  styles: [
    `
    :host {
      display: flex;
    }

    .snack-bar-add-project {
      color: #009ddf;
    }
  `,
  ],
  standalone: true,
  imports: [MatButtonModule, MatSnackBarModule],
})
export class SnackBarAddProjectComponent {
  data: any;

  constructor(@Inject(MAT_SNACK_BAR_DATA) public snackBarData: any, public snackBarRef: MatSnackBarRef<SnackBarAddProjectComponent>){
    // Directly assign the data object
    this.data = snackBarData;
  }
}
