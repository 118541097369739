/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { SecurityScan } from '../models/SecurityScan';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable({
  providedIn: 'root',
})
export class SecurityScanApiService {

    constructor(public readonly http: HttpClient) {}

    /**
     * @returns SecurityScan Success
     * @throws ApiError
     */
    public getApiSecurityScan(): Observable<Array<SecurityScan>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/SecurityScan',
        });
    }

    /**
     * @param requestBody 
     * @returns SecurityScan Success
     * @throws ApiError
     */
    public postApiSecurityScan(
requestBody?: SecurityScan,
): Observable<SecurityScan> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/SecurityScan',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id 
     * @returns SecurityScan Success
     * @throws ApiError
     */
    public getApiSecurityScan1(
id: number,
): Observable<SecurityScan> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/SecurityScan/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id 
     * @param requestBody 
     * @returns any Success
     * @throws ApiError
     */
    public putApiSecurityScan(
id: number,
requestBody?: SecurityScan,
): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/api/SecurityScan/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id 
     * @returns any Success
     * @throws ApiError
     */
    public deleteApiSecurityScan(
id: number,
): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/api/SecurityScan/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id 
     * @param applicationId 
     * @returns SecurityScan Success
     * @throws ApiError
     */
    public getApiSecurityScanApiApplicationsSecurityScan(
id: number,
applicationId: string,
): Observable<SecurityScan> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/SecurityScan/api/Applications/{applicationID}/SecurityScan/{id}',
            path: {
                'id': id,
                'applicationID': applicationId,
            },
        });
    }

}
