<div class="main-div" *ngFor="let endpoint of endpoints">
<p></p>
  <p></p>

<div class="reportSection">
  <div class="sectionTitle">Summary</div>
  <div class="sectionBody">
    <div id="rating">

      <div class="ratingTitle">Overall Rating</div>

      <div class="{{endPointGradeColor(endpoint.grade)}} " id="gradeA">
        {{endpoint.grade}}
      </div>









    </div>

    <div id="chart">

      <div class="chartScale">
        <div class="chartScaleDiv"></div>
        <div class="chartScaleDiv"></div>
        <div class="chartScaleDiv"></div>
        <div class="chartScaleDiv"></div>
        <div class="chartScaleDiv"></div>
        <div class="chartScaleDiv zeroMargin"></div>
        <div class="chartScaleLabelRow">
          <div class="chartScaleLabel">0</div>
          <div class="chartScaleLabel">20</div>
          <div class="chartScaleLabel">40</div>
          <div class="chartScaleLabel">60</div>
          <div class="chartScaleLabel">80</div>
          <div class="chartScaleLabel">100</div>
        </div>
      </div>

      <div class="chartBody">
        <div class="chartRow">
          <div class="chartLabel">Certificate</div>
          <div class="chartBar_g category_width_300" style="width:{{calculateCertificateScore()}}%"></div>
        </div>

        <div class="chartRow">
          <div class="chartLabel">Protocol Support</div>
          <div class="{{calculateProtocolColorGrade(endpoint)}} category_width_300 " style="width:{{calculateProtocolScore(endpoint)}}%">&nbsp;</div>
        </div>

        <div class="chartRow">
          <div class="chartLabel">Key Exchange</div>
          <div class="{{caculateKeyColorGrade()}} category_width_270" style="width:{{calculateKeyScore()}}%">&nbsp;</div>
        </div>

        <div class="chartRow">
          <div class="chartLabel">Cipher Strength</div>
          <div class="{{calculateCipherColorGrade(endpoint)}} category_width_270" style="width:{{caculateCipherScore(endpoint)}}%">&nbsp;</div>
        </div>
      </div>

    </div>

    <br clear="all">

    <div class="infoBox">
      Visit our <a href="https://www.ssllabs.com/projects/documentation/index.html" rel="noreferrer">documentation page</a>
      for more information, configuration guides, and books. Known issues are documented
      <a href="https://community.qualys.com/docs/DOC-4865">here</a>.
    </div>
</div>
</div>
</div>
