<div>
  <div class="project-history-row" *ngFor="let securityscan of securityScans">
    <div class="date-view">
      <mat-icon class="icon" aria-hidden="true" aria-label="panorama fish eye icon" fontIcon="panorama_fish_eye"></mat-icon>
      <div class="date-text">
        {{securityscan.dateSecurityScanPerformed | date: 'medium'}}
      </div>

    </div>
    <div class="whole-view">
    <mat-card class="mat-card-root" (click)="handleClick(securityscan)">
      <mat-card-content class="content">
        <div class="project-history-row" >
          <div class="project-history-row-item" *ngIf="true">
              <mat-card class="card-items">
                <mat-card-title class="card-contents"> SSL Labs</mat-card-title>
                <div class="score-container">
                  <div class={{endPointGradeColor(securityscan.securityScanPrimaryGrade?.sslLabsGrade)}}>{{securityscan.securityScanPrimaryGrade?.sslLabsGrade ?? 'X'}} </div>
                </div>
              </mat-card>
            </div>
            <div class="project-history-row-item">
              <mat-card class="card-items">
                <mat-card-title class="card-contents">SecurityHeaders</mat-card-title>
                  <div class="score-container">
                  <div class={{endPointGradeColor(securityscan.securityScanPrimaryGrade?.securityHeadersGrade)}}>{{securityscan.securityScanPrimaryGrade?.securityHeadersGrade  ?? 'X'}} </div>
                  </div>
                </mat-card>
            </div>
          <div class="project-history-row-item">
            <mat-card class="card-items">
              <mat-card-title class="card-contents"><div class="ssl-score">Average</div></mat-card-title>
                <div class="score-container">
                  <div [ngClass]="endPointGradeColor(AverageGrade(securityscan.securityScanPrimaryGrade?.securityHeadersGrade, securityscan.securityScanPrimaryGrade?.sslLabsGrade))">{{AverageGrade(securityscan.securityScanPrimaryGrade?.securityHeadersGrade, securityscan.securityScanPrimaryGrade?.sslLabsGrade)}} </div>
                </div>
            </mat-card>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>
</div>
